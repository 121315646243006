<div class="banner-container">
  <div class="banners-swiper-container">
    <div class="banner-section">
      <picture class="banner-image-container">
        <source width="639" height="520" srcset='assets/img/banners/Mobile.webp'
          media="(max-width: 639.9px)" type="image/webp">
        <source width="639" height="520" srcset='assets/img/banners/Mobile.png'
          media="(max-width: 639.9px)" type="image/png">
        <source width="1024" height="500" srcset='assets/img/banners/Tablet.webp'
          media="(max-width: 1024.9px)" type="image/webp">
        <source width="1024" height="500" srcset='assets/img/banners/Tablet.png'
          media="(max-width: 1024.9px)" type="image/png">
        <source width="2000" height="500" srcset='assets/img/banners/Desktop.webp'
          media="(min-width: 1025px)" type="image/webp">
        <source width="2000" height="500" srcset='assets/img/banners/Desktop.png'
          media="(min-width: 1025px)" type="image/png">
        <img width="2000" height="500" src="assets/img/banners/Desktop.png"
          alt="Unlimited Talk, Text & Data for $25 " title="Unlimited Talk, Text & Data for $25" loading="eager">
      </picture>
      <div class="banner-content-container">
        <h2 class="header">UNLIMITED</h2>
        <h4 class="subheader">Talk, Text & Data for</h4>
        <div class="price-label">
          <img src="assets/img/price-label.svg" alt="price label" width="250" height="134" />
          <div class="price">
            <p class="amount"><span class="dollar">$</span>25</p>
            <p class="mo">/mo</p>
          </div>
        </div>
        <button class="button primary" (click)="goToOfferLanding()">Claim Offer</button>
      </div>
    </div>
  </div>
</div>