import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { IBasePlan, MobilePlanItem, PlansConfigurationService } from '@ztarmobile/zwp-service-backend';
import { take, takeWhile } from 'rxjs/operators';
import { CUSTOMER_CARE_NUMBER, WEBSITE_URL } from 'src/environments/environment';
import { MetaService } from '../../services/meta-service.service';
import { ACTIVATION_ROUTE_URLS, SUPPORT_ROUTE_URLS, ROUTE_URLS, SHOP_ROUTE_URLS, PLANS_SHOP_ROUTE_URLS, PHONES_SHOP_ROUTE_URLS } from '../app.routes.names';
import { ContentfulService } from '../../services/contentful.service';
import { AppState } from '../app.service';
import Swiper, { EffectFade, Keyboard, Navigation, Pagination, SwiperOptions } from 'swiper';
import { AnalyticsService } from 'src/services/analytics.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  public featuredPlans: IBasePlan[];
  public plansJsonLDString: any;
  public jsonLDString: any;
  public ROUTE_URLS = ROUTE_URLS;
  public SUPPORT_ROUTE_URLS = SUPPORT_ROUTE_URLS;
  public ACTIVATION_ROUTE_URLS = ACTIVATION_ROUTE_URLS;
  public SHOP_ROUTE_URLS = SHOP_ROUTE_URLS;
  public PHONES_SHOP_ROUTE_URLS = PHONES_SHOP_ROUTE_URLS;
  public slides: any;
  public plansConfig: SwiperOptions = {
    direction: 'horizontal',
    keyboard: false,
    mousewheel: false,
    scrollbar: false,
    centeredSlides: true,
    roundLengths: true,
    loopFillGroupWithBlank: true,
    slideToClickedSlide: true,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    slidesPerView: 'auto',
    initialSlide: 1,
    observer: true,
    observeParents: true,
    effect: 'slide',
    updateOnWindowResize: false,
    navigation: {
      nextEl: '.plans-swiper-button-next',
      prevEl: '.plans-swiper-button-prev'
    },
    pagination: {
      el: '.plans-slider-pagination',
      renderBullet: (index, className) => `<li style="z-index: 10" class="slick-active ${className}"><button aria-label="swiper dot"></button></li>`,
      clickable: true
    },
    spaceBetween: 0
  };
  public testimonialsConfig: SwiperOptions = {
    direction: 'horizontal',
    keyboard: false,
    mousewheel: false,
    scrollbar: false,
    roundLengths: true,
    loopFillGroupWithBlank: true,
    slideToClickedSlide: true,
    initialSlide: 0,
    observer: true,
    navigation: {
      nextEl: '.testimonial-swiper-button-next',
      prevEl: '.testimonial-swiper-button-prev'
    },
    spaceBetween: 0,
    breakpoints: {
      320: { // min-width
        slidesPerView: 1,
        centeredSlides: true
      },
      640: {
        slidesPerView: 'auto',
        centeredSlides: true
      }
    }
  };
  public cardIndex = 1;
  public swiper: any;
  public innerWidth: any;

  private alive = true;
  private fromEmptyCart = false;
  private allBasePlans: Array<IBasePlan>;

  constructor(
    private router: Router,
    private metaService: MetaService,
    private appState: AppState,
    private route: ActivatedRoute,
    private location: Location,
    private cdr: ChangeDetectorRef,
    private contentfulService: ContentfulService,
    private plansConfigurationService: PlansConfigurationService,
    private analyticsService: AnalyticsService) {

    this.plansConfigurationService.planConfiguration.pipe(take(1)).subscribe((conf) => {
      this.allBasePlans = conf.allPlans;
      this.featuredPlans = this.allBasePlans.filter((plan) => plan.featured && !plan.archived);
      this.featuredPlans.forEach(plan => {
        if (!!plan.specialPromotion && !!plan.specialPromotion.promotionDiscount) {
          const discountAmountValue = plan.specialPromotion.promotionDiscount.split('%')[0];
          const discountValue = parseInt(discountAmountValue, 10);
          const total = (plan.price - plan.promoPrice) * (discountValue / 100);
          plan.priceAfterSpecialPromoDiscount = total;
        }
        else {
          plan.priceAfterSpecialPromoDiscount = plan.price - plan.promoPrice;
        }
      });
      this.featuredPlans.sort((a, b) => a.price - b.price);
      this.prepareShemaDataForPlans();
      this.trackItemsList(this.featuredPlans);
    });
    this.metaService.createCanonicalUrl(WEBSITE_URL);
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.route.params.pipe(takeWhile(() => this.alive)).subscribe((params: Params) => {
      if (!!params) {
        this.fromEmptyCart = !!params[ROUTE_URLS.PARAMS.CART] ? true : false;
      }
    });
    this.contentfulService.getTestimonialsByPageId('testimonials', 'msp').subscribe((socialSwiperSlides) => {
      this.slides = socialSwiperSlides;
      this.prepareSchemaDataForReview();
    });
    this.cdr.detectChanges();
    this.checkCreatingSwiper();
    this.cdr.detectChanges();
    this.createTestimonialSwiper();
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.cdr.detectChanges();
      this.checkCreatingSwiper();
    }, 200);
  }
  ngOnDestroy(): void {
    this.alive = false;
  }
  public createPlansSwiper(): void {
    this.swiper = new Swiper('.plans-swiper-container', {
      hashNavigation: true,
      modules: [Navigation, Pagination, Keyboard],
      ...this.plansConfig,
    });
    this.cdr.detectChanges();
  }

  public createTestimonialSwiper(): void {
    const testimonialSwiper = new Swiper('.testimonial-swiper-container', {
      hashNavigation: true,
      modules: [Navigation, Pagination, EffectFade, Keyboard],
      ...this.testimonialsConfig
    });
    this.cdr.detectChanges();
  }

  public goToPlans(months?: number): void {
    if (!!months) {
      const params = {};
      params[PLANS_SHOP_ROUTE_URLS.PARAMS.SUBSCRIPTION_CYCLE] = months;
      this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.PLANS_AND_FEATURES}/${PLANS_SHOP_ROUTE_URLS.NEW_PLAN}`, params]);
    } else {
      this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.PLANS_AND_FEATURES}/${PLANS_SHOP_ROUTE_URLS.NEW_PLAN}`]);
    }
  }
  public goToPlanDetails(data?: number): void {
      const params = {};
      params['data'] = data;
      this.router.navigate([`${SHOP_ROUTE_URLS.BASE}${PLANS_SHOP_ROUTE_URLS.BASE}/${PLANS_SHOP_ROUTE_URLS.DETAILS}`, params]);
  }

  private checkCreatingSwiper(): void {
    if (!!this.swiper) {
      this.swiper.destroy(true, true);
      this.swiper = null;
    }
    this.cdr.detectChanges();
    this.createPlansSwiper();
  }

  private prepareSchemaDataForReview(): void {
    const userReviews = [];
    this.slides.map((review) => {
      userReviews.push({
        "@type": "Review",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5"
        },
        "name": "Good2go Mobile",
        "author": {
          "@type": "Person",
          "name": review?.fields?.userDetails?.split(',')[0]
        },
        "reviewBody": review?.fields?.userMessage,
        "publisher": {
          "@type": "Organization",
          "name": "Good2go Mobile"
        }
      });
    });
    const structuredData = {
      '@type': 'Organization',
      '@id': `${WEBSITE_URL}/#organization`,
      name: 'Good2go Mobile',
      url: WEBSITE_URL,
      logo: `${WEBSITE_URL}/assets/img/logo.png`,
      contactPoint: [{
        '@type': 'ContactPoint',
        telephone: CUSTOMER_CARE_NUMBER,
        contactType: 'Customer Care',
        areaServed: 'US'
      }],
      sameAs: ['https://facebook.com/Good2GoMobile', 'https://twitter.com/Good2GoMobile', 'https://instagram.com/good2goMobile'],
      review: [userReviews],
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "5.0",
        "reviewCount": "103"
      }
    };
    this.jsonLDString = this.appState.setJsonLdData(structuredData);
  }
  public calculatePlanPricePerMonth(plan: MobilePlanItem): any {
    return (this.appState.calculateTotalPlanPrices(plan) / plan?.subscriptionCycles);
  }
  public calculateTotalPlanPrices(plan: MobilePlanItem): any {
    return this.appState.calculateTotalPlanPrices(plan);
  }
  private prepareShemaDataForPlans(): void {
    const baseURL = 'https://www.good2gomobile.com';
    const url = `${baseURL}/${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.PLANS_AND_FEATURES}/${PLANS_SHOP_ROUTE_URLS.NEW_PLAN}`;
    const plans = [];
    this.featuredPlans.map((plan) => {
      plans.push({
        '@type': 'Product',
        'name': 'Good2Go Mobile Plan ' + plan?.title,
        'sku': plan?.id,
        'price': this.calculateTotalPlanPrices(plan as any),
        'image': `${baseURL}/assets/icon/plan_thumbnail.svg`,
        'description': plan?.subtitle,
        'url': url + '/details;id=' + plan?.id,
        'priceCurrency': 'USD',
        'availability': 'https://schema.org/OnlineOnly',
        'category': 'Mobile Plans',
        'offers': [
          {
            '@type': 'Offer',
            'price': this.calculateTotalPlanPrices(plan as any),
            'description': plan?.subtitle,
            'priceCurrency': 'USD',
            'availability': 'https://schema.org/OnlineOnly',
          }]
      })
    });
    const featuredPlans = {
      '@type': 'ProductGroup',
      '@id': baseURL + '#featured_plans',
      'productGroupID': '#featured_plans',
      'name': 'Good2Go Mobile Featured Plans ',
      'brand': {
        '@type': 'Brand',
        'name': 'Good2Go Mobile'
      },
      'description': `The best cell phone deal just got better! Get all the power of the nation’s largest networks for less.`,
      'url': baseURL,
      'mainEntityOfPage': baseURL,
      'hasVariant': plans,
    };
    this.plansJsonLDString = this.appState.setJsonLdData(featuredPlans);
  }

  private trackItemsList(plans): void {
    const itemsList = [];
    plans.map((p) => {
      let planItem = this.analyticsService.setPlanItem(p)
      itemsList.push(planItem);
    })
    this.analyticsService.trackViewItemList('featured-plans-list', 'Featured Plans List', itemsList);
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (!!this.fromEmptyCart) {
      this.location.back();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.innerWidth = document.documentElement.clientWidth;
    this.checkCreatingSwiper();
  }
}
