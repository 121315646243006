import { Injectable } from '@angular/core';
import { IUserPlan, IFirebaseAddress, IFirebasePaymentMethod, CustomizableMobilePlan } from '@ztarmobile/zwp-service-backend';
import { IUser } from '@ztarmobile/zwp-services-auth';
import { ManageAddressModalComponent } from '../modals/manage-address-modal/manage-address-modal.component';
import { ManagePaymentSpecificModalComponent } from '../modals/manage-payment-specific-modal/manage-payment-specific-modal.component';
import { ConfirmMessageModalComponent } from '../modals/confirm-message-modal/confirm-message-modal.component';
import { ConfirmPasswordModalComponent } from '../modals/confirm-password-modal/confirm-password-modal.component';
import { InformationMessageModalComponent } from '../modals/information-message-modal/information-message-modal.component';
import { AddActivatedNumberModalComponent } from '../modals/add-activated-number-modal/add-activated-number-modal.component';
import { InputModalComponent } from '../modals/input-modal/input-modal.component';
import { RoutingModalComponent } from '../modals/routing-modal/routing-modal.component';
import { PromotionTermsModalComponent } from '../modals/promotion-terms-modal/promotion-terms-modal.component';
import { EditCcModalComponent } from '../modals/edit-cc-modal/edit-cc-modal.component';
import { SelectPaymentModalComponent } from '../modals/select-payment-modal/select-payment-modal.component';
import { ShippingAddressModalComponent } from '../modals/shipping-address-modal/shipping-address-modal.component';
import { SimReplacementModalComponent } from '../modals/sim-replacement-modal/sim-replacement-modal.component';
import { WifiCallingModalComponent } from '../modals/wifi-calling-modal/wifi-calling-modal.component';
import { MigrationStepsComponent } from '../modals/migration-steps/migration-steps.component';
import { IsLteModalComponent } from '../modals/is-lte-modal/is-lte-modal.component';
import { DynamicModalsComponent } from '../modals/dynamic-modals/dynamic-modals.component';
import { ReferralPromotionComponent } from '../modals/referral-promotion-modal/referral-promotion.component';
import { FiveGModalComponent } from '../modals/five-gmodal/five-gmodal.component';
import { OutOfStockItemModalComponent } from '../modals/out-of-stock-item-modal/out-of-stock-item-modal.component';
import { AcpModalComponent } from '../modals/acp-modal/acp-modal.component';
import { PhoneNotImpactedModalComponent } from '../modals/phone-not-impacted-modal/phone-not-impacted-modal.component';
import { IShipmentTracking } from '@ztarmobile/zwp-service-backend-v2';
import { TrackingModalComponent } from '../modals/tracking-details-modal/tracking-details-modal.component';
import { MdnsListModalComponent } from '../modals/mdns-list-modal/mdns-list-modal.component';
import { CompatibilitySkipModalComponent } from '../modals/compatibility-skip-modal/compatibility-skip-modal.component';
import { eSimReplacementModalComponent } from '../modals/esim-replacement-modal/esim-replacement-modal.component';
import { MigrationConfirmationModalComponent } from '../modals/migration-confirmation-modal/migration-confirmation-modal.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { successModalComponent } from '../modals/success-modal/success-modal.component';
import { AlertSecurityModalComponent } from '../modals/alert-security-modal/alert-security-modal.component';
import { FuturePayProcessComponent } from '../modals/future-pay-process/future-pay-process.component';
import { CheckoutLoginComponent } from 'src/modals/checkout-login/checkout-login.component';
import { MigrationVerifiedFlowComponent } from 'src/modals/migration-verified-flow/migration-verified-flow.component';
import { MyTabModalComponent } from 'src/modals/my-tab-modal/my-tab-modal.component';
import { ErrorModalComponent } from 'src/modals/error-modal/error-modal.component';
import { CanadianLocationComponent } from 'src/modals/canadian-location/canadian-location.component';
import { VerifyAddressModalComponent } from 'src/modals/verify-address-modal/verify-address-modal.component';
import { InstractionsFourGModalComponent } from 'src/modals/instractions-four-g-modal/instractions-four-g-modal.component';
import { InstractionsFiveGModalComponent } from 'src/modals/instractions-five-g-modal/instractions-five-g-modal.component';
import { InstractionsFiveGIos17ModalComponent } from 'src/modals/instractions-five-g-ios17-modal/instractions-five-g-ios17-modal.component';
import { GoogleLoginComponent } from 'src/modals/google-login/google-login.component';
import { RecaptchaVerificationComponent } from 'src/modals/recaptcha-verification/recaptcha-verification.component';
import { PlansFeaturesComponent } from 'src/modals/plans-features/plans-features.component';

export interface ModalSetting {
  title?: string;
  okText?: string;
  cancelText?: string;
  customClass?: string;
  enableHTML?: boolean;
}
export interface IModalHelper {
  showManageShippingAddressesModal(user: IUser, userPlan: IUserPlan, isManage?: boolean, addressId?: string, customClass?: string, accountSettingsRoute?: string): MatDialogRef<any>;
  showSpecificManagePaymentModal(user: IUser, userPlan: IUserPlan, paymentId?: string, customClass?: string): MatDialogRef<any>;
  showConfirmMessageModal(title: string, message: string, okText?: string, cancelText?: string, customClass?: string, customHTML?: string): MatDialogRef<any>;
  showConfirmMessage(message: string, settings?: ModalSetting, customHTML?: string): MatDialogRef<any>;
  showConfirmPasswordModal(title: string, message: string, customClass?: string): MatDialogRef<any>;
  showInformationMessageModal(title: string, message: string, btnText?: string, btnUrl?: string, hasCloseLink?: boolean, customClass?: string, customHTML?: string,
    cancelBtn?: boolean, cancelText?: string, noteText?: string, specificCancelReturn?: string): MatDialogRef<any>;
  showAddActivatedNumberModal(customClass?: string, title?: string, label?: string): MatDialogRef<any>;
  showInputModal(title: string, message: string, okText?: string, okBtnClass?: string, customClass?: string,
    isCancelable?: boolean, cancelText?: string, cancelBtnClass?: string, labelText?: string): MatDialogRef<any>;
  showRoutingModal(title: string, message: string, hasCloseLink?: boolean, yesButtonText?: string, noButtonText?: string,
    skipButtonText?: string, yesButtonLink?: string, noButtonLink?: string, skipButtonLink?: string, customClass?: string): MatDialogRef<any>;
  showPromotionTermsModal(SUPPORT_ROUTE_URLS: any, title?: string, hasCloseLink?: boolean, message1?: string, message2?: string, customClass?: string): MatDialogRef<any>;
  showEditCreditCardModal(paymentMethod: IFirebasePaymentMethod, title: string, note: string, noteLinkText: string, customClass?: string): MatDialogRef<any>;
  showSelectCreditCardModal(paymentList: Array<IFirebasePaymentMethod>, title: string, mdn: string, buttonText: string, customClass?: string): MatDialogRef<any>;
  showShippingAddressModal(title: string, shippingAddress?: IFirebaseAddress, customClass?: string): MatDialogRef<any>;
  showSIMModal(title: string, message: string, okText?: string, okBtnClass?: string, customClass?: string,
    network?: string, labelText?: string, showRecaptcha?: boolean, iccid?: string): MatDialogRef<any>;
  showWifiCallingModal(title: string, termsRoute: string, customClass?: string, wifiAddress?: IFirebaseAddress): MatDialogRef<any>;
  showMigrationStepsModal(customClass?: string): MatDialogRef<any>;
  showReferralPromotionModal(customClass?: string): MatDialogRef<any>;
  showIsLteOptionsModal(title: string, customClass?: string): MatDialogRef<any>;
  showDynamicModal(title: string, message: string, btnText?: string, btnUrl?: string, hasCloseLink?: boolean, customClass?: string, customHTML?: string, contentfulModel?: string,
    renderElementId?: string, richTextId?: string, cancelBtn?: boolean, cancelText?: string, noteText?: string): MatDialogRef<any>;
  showFiveGModal(title: string, customHTML: string, linkText: string, linkRoute: any, customClass?: string, hasCloseLink?: boolean): MatDialogRef<any>;
  successModal(message: string, buttonText?: string, customClass?: string, title?: string): MatDialogRef<any>;
  // tslint:disable-next-line:max-line-length
  showItemOutOFStockModal(title: string, customHTML: string, currentPlan: CustomizableMobilePlan, customClass?: string, hasCloseLink?: boolean): MatDialogRef<any>;
  showACPModal(title: string, customHTML: string, primaryButton?: string, secondaryButton?: string, customClass?: string, hasCloseLink?: boolean): MatDialogRef<any>;
  showNotImpactedModal(title: string, message: string, customClass?: string): MatDialogRef<any>;
  showTrackingModal(title: string, trackingDetails: IShipmentTracking, trackingNumber: string, customClass?: string): MatDialogRef<any>;
  showMdnsListModal(title: string, associatedPlans: Array<IUserPlan>, paymentId: string, customClass?: string): MatDialogRef<any>;
  showTMOSkipModal(title: string, hasCloseLink?: boolean, customClass?: string, notBeforeSkipping?: boolean): MatDialogRef<any>;
  showeSIMModal(iccid: string, mdn: string, customClass?: string): MatDialogRef<any>;
  showeMigrationConfirmationModal(phone: string, imei: string, customClass?: string): MatDialogRef<any>;
  showAlertSecurityModal(title: string, primaryBtn?: string, secondaryBtn?: string, timer?: boolean, customClass?: string, customHTML?: string): MatDialogRef<any>;
  showFuturePayProcessPopup(shippingDetails: any): MatDialogRef<any>;
  showLoginPopup(): MatDialogRef<any>;
  showMigrationVerfiedFlow(title: string, customClass?: string, hasCloseLink?: boolean, selectedPlan?: IUserPlan, phoneImei?: string, planMdn?: string, selectedPlanId?: string, activationCode?: string): MatDialogRef<any>;
  showMyTabPopup(): MatDialogRef<any>;
  showErrorModal(title: string, message: string, customHTML: string, customClass: string, btnText: string): MatDialogRef<any>;
  showCanadianLocationModal(): MatDialogRef<any>;
  showVerifyAddressModal(title: string, message: string, customHTML?: string, customClass?: string, hasCloseLink?: boolean): MatDialogRef<any>;
}
@Injectable({
  providedIn: 'root'
})
export class ModalHelperService {
  constructor(private dialog: MatDialog) {
  }

  public showManageShippingAddressesModal(user: IUser, userPlan: IUserPlan, isManage?: boolean, addressId?: string, customClass?: string,
    accountSettingsRoute?: string): MatDialogRef<any> {
    return this.dialog.open(ManageAddressModalComponent, {
      data: {
        isManage,
        userPlan,
        user,
        addressId,
        customClass,
        accountSettingsRoute
      }
    });
  }
  public showACPModal(title: string, customHTML: string, primaryButton?: string, secondaryButton?: string, customClass?: string, hasCloseLink?: boolean): MatDialogRef<any> {
    return this.dialog.open(AcpModalComponent, {
      data: {
        title,
        customHTML,
        primaryButton,
        secondaryButton,
        customClass,
        hasCloseLink
      }
    });
  }
  public showSpecificManagePaymentModal(user: IUser, userPlan: IUserPlan, paymentId?: string, customClass?: string,
    accountSettingsRoute?: string): MatDialogRef<any> {
    return this.dialog.open(ManagePaymentSpecificModalComponent, {
      data: {
        paymentMethodId: paymentId,
        userPlan,
        user,
        customClass,
        accountSettingsRoute
      }
    });
  }

  public showConfirmMessageModal(title: string, message: string, okText?: string, cancelText?: string, customClass?: string, customHTML?: string): MatDialogRef<any> {
    console.warn('You are using showConfirmMessageModal, please use showConfirmMessage instead');
    return this.showConfirmMessage(message, { title, okText, cancelText, customClass, enableHTML: false }, customHTML);
  }
  public showConfirmMessage(message: string, settings?: ModalSetting, customHTML?: string): MatDialogRef<any> {
    return this.dialog.open(ConfirmMessageModalComponent, {
      data: {
        message, settings, customHTML
      }
    });
  }
  public showConfirmPasswordModal(title: string, message: string, customClass?: string): MatDialogRef<any> {
    return this.dialog.open(ConfirmPasswordModalComponent, {
      data: {
        message, title, customClass
      }
    });
  }

  public showInformationMessageModal(title: string, message: string, btnText?: string, btnUrl?: string, hasCloseLink?: boolean, customClass?: string,
    customHTML?: string, cancelBtn?: boolean, cancelText?: string, noteText?: string, specificCancelReturn?: string): MatDialogRef<any> {
    return this.dialog.open(InformationMessageModalComponent, {
      data: {
        title, message, btnText, btnUrl, hasCloseLink, customClass,
        customHTML, cancelBtn, cancelText, noteText, specificCancelReturn
      }
    });

  }
  public showFiveGModal(title: string, customHTML: string, linkText: string, linkRoute: any, customClass?: string, hasCloseLink?: boolean): MatDialogRef<any> {
    return this.dialog.open(FiveGModalComponent, {
      data: {
        title, customHTML, linkText, linkRoute, customClass, hasCloseLink
      }
    });
  }
  public successModal(message: string, buttonText?: string, customClass?: string, title?: string): MatDialogRef<any> {
    return this.dialog.open(successModalComponent, {
      data: {
        message, buttonText, customClass, title
      }
    });
  }
  // tslint:disable-next-line:max-line-length
  public showItemOutOFStockModal(title: string, customHTML: string, currentPlan: CustomizableMobilePlan, customClass?: string, hasCloseLink?: boolean): MatDialogRef<any> {
    return this.dialog.open(OutOfStockItemModalComponent, {
      data: {
        title, customHTML, currentPlan, customClass, hasCloseLink
      }
    });
  }
  public showAddActivatedNumberModal(customClass?: string, title?: string, label?: string): MatDialogRef<any> {
    return this.dialog.open(AddActivatedNumberModalComponent, {
      data: {
        customClass, title, label
      }
    });
  }
  public showInputModal(title: string, message: string, okText?: string, okBtnClass?: string, customClass?: string,
    isCancelable?: boolean, cancelText?: string, cancelBtnClass?: string, labelText?: string): MatDialogRef<any> {
    return this.dialog.open(InputModalComponent, {
      data: {
        title, message, okText, okBtnClass, customClass,
        isCancelable, cancelText, cancelBtnClass, labelText
      }
    });
  }
  public showRoutingModal(title: string, message: string, hasCloseLink?: boolean, yesButtonText?: string, noButtonText?: string,
    skipButtonText?: string, yesButtonLink?: string, noButtonLink?: string, skipButtonLink?: string, customClass?: string): MatDialogRef<any> {
    return this.dialog.open(RoutingModalComponent, {
      data: {
        title, message, hasCloseLink, yesButtonText, noButtonText, skipButtonText, yesButtonLink, noButtonLink, skipButtonLink, customClass
      }
    });
  }
  public showPromotionTermsModal(SUPPORT_ROUTE_URLS: any, title?: string, hasCloseLink?: boolean, message1?: string, message2?: string, customClass?: string): MatDialogRef<any> {
    return this.dialog.open(PromotionTermsModalComponent, {
      data: {
        SUPPORT_ROUTE_URLS, title, hasCloseLink, message1, message2, customClass
      }
    });
  }

  showEditCreditCardModal(paymentMethod: IFirebasePaymentMethod, title: string, note: string, noteLinkText: string, customClass?: string): MatDialogRef<any> {
    return this.dialog.open(EditCcModalComponent, {
      data: {
        paymentMethod, title, note, noteLinkText, customClass
      }
    });
  }

  showSelectCreditCardModal(paymentList: Array<IFirebasePaymentMethod>, title: string, mdn: string, buttonText: string, customClass?: string): MatDialogRef<any> {
    return this.dialog.open(SelectPaymentModalComponent, {
      data: {
        paymentList, title, mdn, buttonText, customClass
      }
    });
  }
  showShippingAddressModal(title: string, shippingAddress?: IFirebaseAddress, customClass?: string): MatDialogRef<any> {
    return this.dialog.open(ShippingAddressModalComponent, {
      data: {
        title, shippingAddress, customClass
      }
    });
  }

  showSIMModal(title: string, message: string, okText?: string, okBtnClass?: string, customClass?: string,
    network?: string, labelText?: string, showRecaptcha?: boolean, iccid?: string): MatDialogRef<any> {
    return this.dialog.open(SimReplacementModalComponent, {
      data: {
        title, message, okText, okBtnClass, customClass, network, labelText, showRecaptcha, iccid
      }
    });
  }
  showeSIMModal(iccid: string, mdn: string, customClass?: string): MatDialogRef<any> {
    return this.dialog.open(eSimReplacementModalComponent, {
      data: {
        iccid, mdn, customClass
      }
    });
  }
  showWifiCallingModal(title: string, termsRoute: string, customClass?: string, wifiAddress?: IFirebaseAddress): MatDialogRef<any> {
    return this.dialog.open(WifiCallingModalComponent, {
      data: {
        title, termsRoute, customClass, wifiAddress
      }
    });
  }
  showMigrationStepsModal(customClass?: string): MatDialogRef<any> {
    return this.dialog.open(MigrationStepsComponent, {
      data: {
        customClass
      }
    });
  }
  showReferralPromotionModal(customClass?: string): MatDialogRef<any> {
    return this.dialog.open(ReferralPromotionComponent, {
      data: {
        customClass
      }
    });
  }
  showIsLteOptionsModal(title: string, customClass?: string): MatDialogRef<any> {
    return this.dialog.open(IsLteModalComponent, {
      data: {
        title,
        customClass
      }
    });
  }

  showNotImpactedModal(title: string, message: string, customClass?: string): MatDialogRef<any> {
    return this.dialog.open(PhoneNotImpactedModalComponent, {
      data: {
        title,
        message,
        customClass
      }
    });
  }
  public showDynamicModal(title: string, message: string, btnText?: string, btnUrl?: string, hasCloseLink?: boolean, customClass?: string,
    customHTML?: string, contentfulModel?: string, renderElementId?: string, richTextId?: string,
    cancelBtn?: boolean, cancelText?: string, noteText?: string): MatDialogRef<any> {
    return this.dialog.open(DynamicModalsComponent, {
      data: {
        title, message, btnText, btnUrl, hasCloseLink, customClass,
        customHTML, contentfulModel, richTextId, renderElementId, cancelBtn, cancelText, noteText
      }
    });
  }

  public showTrackingModal(title: string, trackingDetails: IShipmentTracking, trackingNumber: string, customClass?: string): MatDialogRef<any> {
    return this.dialog.open(TrackingModalComponent, {
      data: {
        title, trackingDetails, trackingNumber, customClass
      }
    });
  }
  public showMdnsListModal(title: string, associatedPlans: Array<IUserPlan>, paymentId: string, customClass?: string): MatDialogRef<any> {
    return this.dialog.open(MdnsListModalComponent, {
      data: {
        title, associatedPlans, paymentId, customClass
      }
    });
  }
  public showTMOSkipModal(title: string, hasCloseLink?: boolean, customClass?: string, notBeforeSkipping?: boolean): MatDialogRef<any> {
    return this.dialog.open(CompatibilitySkipModalComponent, {
      data: {
        title, hasCloseLink, customClass, notBeforeSkipping
      }
    });
  }
  public showeMigrationConfirmationModal(phone: string, imei: string, customClass?: string): MatDialogRef<any> {
    return this.dialog.open(MigrationConfirmationModalComponent, {
      data: {
        phone, imei, customClass
      }
    });
  }
  public showAlertSecurityModal(title: string, primaryBtn?: string, secondaryBtn?: string, timer?: boolean, customClass?: string, customHTML?: string): MatDialogRef<any> {
    return this.dialog.open(AlertSecurityModalComponent, {
      data: {
        title, primaryBtn, secondaryBtn, timer, customClass, customHTML
      }
    });
  }
  public showFuturePayProcessPopup(futurePayDetails: any): MatDialogRef<any> {
    return this.dialog.open(FuturePayProcessComponent, { data: futurePayDetails });
  }
  public showLoginPopup(): MatDialogRef<any> {
    return this.dialog.open(CheckoutLoginComponent, {});
  }
  public showMigrationVerfiedFlow(title: string, customClass?: string, hasCloseLink?: boolean, selectedPlan?: IUserPlan, phoneImei?: string, planMdn?: string, selectedPlanId?: string, activationCode?: string): MatDialogRef<any> {
    return this.dialog.open(MigrationVerifiedFlowComponent, {
      data: {
        title, customClass, hasCloseLink, selectedPlan, phoneImei, planMdn, selectedPlanId, activationCode
      }
    });
  }
  public showMyTabPopup(): MatDialogRef<any> {
    return this.dialog.open(MyTabModalComponent, {});
  }
  public showErrorModal(title: string, message: string, customHTML?: string, customClass?: string, btnText?: string): MatDialogRef<any> {
    return this.dialog.open(ErrorModalComponent, {
      data: {
        title, message, customHTML, customClass, btnText
      }
    });
  }
  public showCanadianLocationModal(): MatDialogRef<any> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true; // Prevents closing by clicking outside
    dialogConfig.autoFocus = true;
    return this.dialog.open(CanadianLocationComponent, dialogConfig);
  }
  showVerifyAddressModal(title: string, message: string, customHTML?: string, customClass?: string, hasCloseLink?: boolean): MatDialogRef<any> {
    return this.dialog.open(VerifyAddressModalComponent, {
      data: {
        title, message, customHTML, customClass, hasCloseLink
      }
    });
  }

  showIphone4GInstructionsModal() {
    return this.dialog.open(InstractionsFourGModalComponent, {
      width: '600px',
      maxWidth: '97%'
    });
  }

  showIphone5GInstructionsModal() {
    return this.dialog.open(InstractionsFiveGModalComponent, {
      width: '600px',
      maxWidth: '97%'
    });
  }
  showIphone5GIos17InstructionsModal() {
    return this.dialog.open(InstractionsFiveGIos17ModalComponent, {
      width: '600px',
      maxWidth: '97%'
    });
  }
  showGoogleLoginsModal() {
    return this.dialog.open(GoogleLoginComponent, {
      width: '500px',
      maxWidth: '97%',
      height:'200px'
    });
  }
  showRecaptchaVerificationModal() {
    return this.dialog.open(RecaptchaVerificationComponent);
  }
  showPlanFeaturesModal(title?: string, planData?: string) : MatDialogRef<any> {
    return this.dialog.open(PlansFeaturesComponent, {
      data: {
        title, planData
      }
    });
  }
}
